import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Toast, Loader, Button } from '@getvim/atomic-ui';
import { IconTrash } from '../../assets/icons';

import { AppointmentType } from '../../types';
import AppointmentModal from '../appointment-modal';
import ConfirmDeleteModal from '../confirm-delete-modal';
import AppointmentDetailsNavBar from '../appointment-nav-bar';
import { AppointmentLockStatusChip } from '../appointment-status-chip';
import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';

import './styles.less';
import { AppWithOverride } from '../../pages-v2/vim-settings/types';

const { ToastTypes, createToast } = Toast;

const WARN_MESSAGE_ON_DELETE_APPTS = (
  <>
    Do you really want to delete
    <b>
      <i> all patient's appointments</i>
    </b>
    ? Related appointment data will be also deleted: Referrals, Orders, Notes.
  </>
);

export const Appointments = ({ profileData, toggleAppointmentDetailsOpened, onDataUpdate }: { profileData: any, toggleAppointmentDetailsOpened: any, onDataUpdate: (problemList: any) => void}) => {
  const [eventsList, setEventList] = useState<{
    future: null | AppointmentType[];
    past: null | AppointmentType[];
  }>({
    future: null,
    past: null,
  });
  const [selectedAppointment, setSelectedAppointment] = useState<any>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [appointmentToBeDeleted, setAppointmentToBeDeleted] = useState<any>(null);

  const [appointmentsModalVisibility, setAppointmentsModalVisibility] = useState<any>(null);
  const [deleteApptsModalVisibility, setDeleteApptsModalVisibility] = useState<any>(null);

  const [providers, setProviders] = useState<any>([]);
  const [selectedProvider, setSelectedProvider] = useState<{ id: string }>();
  const [providerSlots, setProviderSlots] = useState<any>([]);

  const user = useUserData();
  const { isAdmin, organization } = user;
  const api = useApi();

  useEffect(() => {
    getProviders();
    getPatientAppointments();
  }, []);

  useEffect(() => {
    setSelectedProvider(user);
  }, [user]);

  useEffect(() => {
    if (selectedProvider) {
      getProviderAvailability();
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (deleteConfirmed && appointmentToBeDeleted) {
      deleteAppointment(appointmentToBeDeleted.id);
      setAppointmentToBeDeleted(null);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  const deleteAppointment = async (id: number) => {
    setIsLoading(true);
    await api.deleteAppointment(id);
    await getPatientAppointments();
    setIsLoading(false);
  };

  const getPatientAppointments = async () => {
    setIsLoading(true);
    const appointments: AppointmentType[] = await api.getPatientAppointments({
      id: profileData.id,
    });

    sortAppointments(appointments);
    setIsLoading(false);
  };

  const getProviders = async (search?: string) => {
    const organizationId = isAdmin ? undefined : organization?.id;
    const providers = await api.getProviders({ search, organizationId });

    setProviders(providers.data);
  };

  const getProviderAvailability = async (date?: Date) => {
    const provider = [user, ...providers].find(
      (provider: { id: string }) => provider.id === selectedProvider?.id,
    );
    const locationId = provider.clinics[0]?.location?.id;
    if (!locationId) return;

    const providerSlots = await api.getProviderAvailability({
      id: provider.id,
      locationId,
      startDate: moment(date || new Date())
        .startOf('week')
        .toDate(),
    });

    setProviderSlots(providerSlots);
  };

  const deletePatientAppointments = async () => {
    try {
      await api.deletePatientAppointments(profileData.id);
    } catch (err: any) {
      createToast({
        title: 'Error!',
        message: err.message,
        type: ToastTypes.ERROR,
        html: true,
      });
    }
  };

  const handleDeleteAppointments = async () => {
    setDeleteApptsModalVisibility(null);
    await deletePatientAppointments();
    getPatientAppointments();
  };

  const handleOpenAppointmentDetails = (appointment: AppointmentType) => {
    toggleAppointmentDetailsOpened();
    // @ts-ignore
    setSelectedAppointment(appointment);
  };

  const sortAppointments = (appointments: any[]) => {
    const futureEvents: any[] = [];
    const pastEvents: any[] = [];

    appointments.forEach((el) => {
      if (Date.now() < Date.parse(el.startDate).valueOf()) {
        futureEvents.push(el);
      } else {
        pastEvents.push(el);
      }
    });

    // api returns appts in ASC order, as it is needed for Future appts
    const pastSortedDesc = [...pastEvents].sort(
      (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );

    setEventList({ future: futureEvents, past: pastSortedDesc });
  };

  const AppointmentsList = ({ appointments }: any) => {
    return (
      <div className="appointments-v2__list">
        {appointments.map((appointment: AppointmentType) => {
          const { provider } = appointment;
          const providerName = `${provider.firstName} ${provider.lastName}${
            provider?.suffix ? ', ' + provider?.suffix : ''
          }`;
          return (
            <div
              key={appointment.id}
              data-apptId={appointment.id}
              className="appointments-v2__list-item"
            >
              <div
                className="appointments-v2__list-item__details"
                onClick={() => {
                  handleOpenAppointmentDetails(appointment);
                }}
              >
                <div className="appointments-v2__list-item__info">
                  <span className="appointments-v2__list-item__date">
                    {moment(appointment.startDate).format('MMM DD, YYYY')}
                  </span>
                  <span className="appointments-v2__list-item__summary">{providerName}</span>
                </div>
                {appointment.isLocked && <AppointmentLockStatusChip />}
              </div>
              <div className="appointments-v2__list-item__delete">
                <IconTrash
                  onClick={(e: any) => {
                    e.preventDefault();
                    setAppointmentToBeDeleted(appointment);
                    setConfirmDeleteModalOpen(true);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const { future, past } = eventsList;
  const hasSomeAppts = Boolean(future?.length || past?.length);

  return (
    <div className="appointments-v2">
      {isLoading && !selectedAppointment && <Loader type="dots" size="small" label="Loading" />}

      {!selectedAppointment && (
        <>
          {future && (
            <div className="appointments-v2__list-wrapper">
              <div className="appointments-list-header">
                <div className="appointments-v2__list-title">Future appointments</div>
                <div className="appointments-v2__action-buttons">
                  <Button
                    buttonType="small"
                    width="small"
                    bgColor="themedOutline"
                    className="appointments-v2__delete-all-appts-btn"
                    disabled={!hasSomeAppts}
                    onClick={() => setDeleteApptsModalVisibility({})}
                  >
                    <IconTrash className="appointments-v2__trash-icon" />
                    <span> Delete all appointments</span>
                  </Button>
                  <Button
                    buttonType="small"
                    className="appointments-v2__new-appt-btn"
                    onClick={() => setAppointmentsModalVisibility({})}
                  >
                    <i className="icon-plus-fat i-va-fix-2" />
                    &nbsp;
                    <span> New appointment</span>
                  </Button>
                </div>
              </div>
              {future.length > 0 ? (
                <>
                  <AppointmentsList appointments={future} />
                </>
              ) : (
                <div className="no-appts-message">No future appointments created yet</div>
              )}
            </div>
          )}
          {past && (
            <div className="appointments-v2__list-wrapper">
              <div className="appointments-v2__list-title">Past appointments</div>
              {past.length > 0 ? (
                <>
                  <AppointmentsList appointments={past} />
                </>
              ) : (
                <div className="no-appts-message">No past appointments created yet</div>
              )}
            </div>
          )}
          {confirmDeleteModalOpen && appointmentToBeDeleted && (
            <ConfirmDeleteModal
              dataToBeDeleted="this appointment"
              onConfirmed={() => {
                setDeleteConfirmed(true);
                setConfirmDeleteModalOpen(false);
              }}
              onClose={() => setConfirmDeleteModalOpen(false)}
              isOpen={confirmDeleteModalOpen}
              closeOnBackdrop={false}
            />
          )}
        </>
      )}
      {selectedAppointment && (
        <AppointmentDetailsNavBar
          patient={profileData}
          selectedAppointment={selectedAppointment}
          onAppointmentUpdate={getPatientAppointments}
          onCloseAppointment={() => {
            toggleAppointmentDetailsOpened();
            setSelectedAppointment(undefined);
          }}
          onDataUpdate={onDataUpdate}
        />
      )}
      {appointmentsModalVisibility && (
        <AppointmentModal
          updateSlots={() => {
            getProviderAvailability();
            getPatientAppointments();
          }}
          modalVisibility={appointmentsModalVisibility}
          closeModal={() => setAppointmentsModalVisibility(null)}
          providers={[...(isAdmin ? [user] : []), ...providers]}
          patients={[profileData]}
          providerSlots={providerSlots}
          defaultSelectedProvider={selectedProvider}
          getProviders={getProviders}
          onAppointmentCreate={(appointment) => {
            handleOpenAppointmentDetails(appointment);
          }}
        />
      )}
      <ConfirmDeleteModal
        warnMessage={WARN_MESSAGE_ON_DELETE_APPTS}
        onConfirmed={() => handleDeleteAppointments()}
        onClose={() => setDeleteApptsModalVisibility(null)}
        isOpen={deleteApptsModalVisibility}
      />
    </div>
  );
};
