import { useEffect, useState } from 'react';
import { getSDKLocalStorage, VimTokens } from './sdkLocalStorage';

const TOKENS_KEY = 'vimTokens';

const extractUserToken = (tokens: VimTokens | undefined) => {
  if (!tokens) {
    return undefined;
  }
  const sdkLocalStorage = getSDKLocalStorage();
  const userMail = sdkLocalStorage.sdkUserInfo?.email;
  if (!userMail) {
    return;
  }
  for (const [key, value] of Object.entries(tokens)) {
    if (key.startsWith(userMail)) {
      return value.accessToken;
    }
  }
};

export default function useAccessToken(): string | undefined {
  const [vimOsAccessToken, setVimOsAccessToken] = useState<string | undefined>(() =>
    extractUserToken(getSDKLocalStorage()?.[TOKENS_KEY]),
  );
  useEffect(() => {
    if (!vimOsAccessToken) {
      const intervalId = setInterval(() => {
        setVimOsAccessToken(extractUserToken(getSDKLocalStorage()?.[TOKENS_KEY]));
      }, 3000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [vimOsAccessToken]);
  return vimOsAccessToken;
}
