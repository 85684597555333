import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { EhrSDKContext } from '../../SdkWrapper';
import {
  Calendar,
  IconAudit,
  IconDoctor,
  IconFile,
  IconPatient,
  IconSDK,
  IconSDKArrow,
  IconSignedDoc,
  IconSuitcase,
  IconVimSettings,
} from '../../assets/icons';
import { useUserData } from '../../hooks/useUserData';
import useAccessToken from '../../pages-v2/vim-settings/useAccessToken';
import { UserRole } from '../../types/user';
import { useRoleAccess } from '../../utils/roles';
import './styles.less';

type Props = {
  openVimSettings: () => void;
  isV2TempUrl?: boolean;
};

const SidebarMenu: React.FC<Props> = ({ openVimSettings, isV2TempUrl }) => {
  const { isSdkLoaded } = useContext(EhrSDKContext);
  const accessToken = useAccessToken();
  const { isAuthenticated } = useAuth0();
  const user = useUserData();
  const roleAccess = useRoleAccess();

  const organizationAlias = user?.organization?.alias;

  if (!isAuthenticated) {
    return null;
  }

  const tempV2Prefix = isV2TempUrl ? '/v2' : '';

  return (
    <>
      <ul className="sidebar-menu-v2">
        {user.notFound ? null : (
          <>
            <div className="sidebar-menu-options">
              <li>
                <NavLink
                  id="calendar"
                  activeClassName="active-tab"
                  to={`${tempV2Prefix}/${organizationAlias}/calendar/`}
                >
                  <Calendar />
                  <span>Calendar</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  id="patients"
                  activeClassName="active-tab"
                  to={`${tempV2Prefix}/${organizationAlias}/patients`}
                >
                  <IconPatient />
                  <span>Patients</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  id="reports"
                  activeClassName="active-tab"
                  isActive={(match, location) => {
                    return Boolean(
                      match && location.pathname === `${tempV2Prefix}/${organizationAlias}/`,
                    );
                  }}
                  to={`${tempV2Prefix}/${organizationAlias}/`}
                >
                  <IconSignedDoc />
                  <span>Reports</span>
                </NavLink>
              </li>
              {roleAccess([UserRole.ADMIN]) && (
                <li>
                  <NavLink
                    id="organizations"
                    activeClassName="active-tab"
                    to={`${tempV2Prefix}/${organizationAlias}/organizations/`}
                  >
                    <IconSuitcase />
                    <span>Organizations</span>
                  </NavLink>
                </li>
              )}
              {roleAccess([UserRole.ADMIN]) && (
                <li>
                  <NavLink
                    id="providers"
                    activeClassName="active-tab"
                    to={`${tempV2Prefix}/${organizationAlias}/providers`}
                  >
                    <IconDoctor />
                    <span>Providers</span>
                  </NavLink>
                </li>
              )}
              {roleAccess([UserRole.DATA_ACCESS]) && (
                <li>
                  <NavLink
                    activeClassName="active-tab"
                    to={`${tempV2Prefix}/${organizationAlias}/data-verification/`}
                  >
                    <IconFile />
                    <span>Data Verification</span>
                  </NavLink>
                </li>
              )}
              {roleAccess([UserRole.ADMIN]) && (
                <li>
                  <NavLink
                    activeClassName="active-tab"
                    to={`${tempV2Prefix}/${organizationAlias}/audit`}
                  >
                    <IconAudit />
                    <span>Audit</span>
                  </NavLink>
                </li>
              )}
              <li>
                <a target="_blank" href="https://docs.getvim.com/">
                  <IconSDK />
                  <span>SDK Docs</span>
                  <IconSDKArrow className="arrow-icon" />
                </a>
              </li>
            </div>
            {isSdkLoaded && accessToken && (
              <div className="sidebar-vim-settings">
                <li>
                  <a onClick={openVimSettings} data-testid="open-vim-settings-button">
                    <IconVimSettings />
                    <span>Vim Settings</span>
                  </a>
                </li>
              </div>
            )}
          </>
        )}
      </ul>
    </>
  );
};

export default SidebarMenu;
