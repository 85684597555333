import React, { useCallback, useState } from 'react';
import { Button, Tooltip } from '@getvim/atomic-ui';
import { IconCopy } from '../../assets/icons';
import useAccessToken from './useAccessToken';

const AccessToken: React.FC = () => {
  const vimOsAccessToken = useAccessToken();

  const [copyClicked, setCopyClicked] = useState<boolean>(false);
  const copyAccessToken = useCallback(() => {
    if (vimOsAccessToken) {
      window.navigator.clipboard.writeText(vimOsAccessToken);
      setCopyClicked(true);
      setTimeout(() => {
        setCopyClicked(false);
      }, 5000);
    }
  }, [vimOsAccessToken]);

  return (
    <div className="content-section">
      <div className="section-title">
        <div>Application authentication</div>
      </div>
      <div className="section-content">
        <span className="section-text">
          You can use this access token (valid for 30 minutes) to test your application
          authentication flow separately.
          <a
            href="https://docs.getvim.com/vim-os-js/authentication.html#testing-the-authentication-flow"
            target="_blank"
            rel="noreferrer"
            className="section-title-url"
          >
            Learn more
          </a>
        </span>
        <div>
          <Tooltip
            hideTooltip={vimOsAccessToken !== undefined}
            tooltipContent="You don't have any access token yet, Vim OS hasn't logged in yet."
            portal={false}
          >
            <Button
              buttonType="small"
              disabled={vimOsAccessToken === undefined}
              bgColor="white"
              onClick={copyAccessToken}
            >
              {copyClicked ? (
                <>
                  <span>Copied</span>&nbsp;&nbsp;
                  <span className="icon-check" />
                </>
              ) : (
                <div className="flex-button">
                  <IconCopy />
                  <span>Copy Access Token</span>
                </div>
              )}
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default AccessToken;
