import React, { forwardRef, useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Tooltip } from '@getvim/atomic-ui';

import './styles.less';

export enum DescriptionPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface SelectTypeaheadProps {
  description: string;
  labelKey: string;
  filterBy?: string[];
  disableFilter?: boolean;
  className: string;
  options: any[];
  onInputChange?: (searchTerm: string) => void;
  renderMenuItemChildren?(value: string, code: string, isLoadingStatus?: boolean): any;
  ref?: any;
  error: any;
  submitted?: boolean;
  placeholder?: string;
  disabled?: boolean;
  tooltipText?: string;
  descriptionPosition?: DescriptionPosition;
  isLoadingFlag?: boolean;
  loadMoreOptions?: (skip: number, searchTerm?: string) => Promise<{ data: any[]; total: any }>;
  optionsTotal?: number;
}

export const SelectTypeahead = forwardRef((props: SelectTypeaheadProps, ref: any) => {
  const {
    options,
    description,
    labelKey,
    filterBy,
    disableFilter = false,
    className,
    error,
    submitted,
    placeholder,
    disabled,
    tooltipText,
    descriptionPosition = DescriptionPosition.BOTTOM,
    isLoadingFlag,
    loadMoreOptions,
    onInputChange,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [allOptions, setAllOptions] = useState(options);
  const [totalCount, setTotalCount] = useState();
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setAllOptions([...options]);
  }, [options]);

  const loadOptionsOnScroll = async () => {
    if (!loadMoreOptions || allOptions.length === totalCount) return;

    setIsLoading(true);

    const { data: newOptions, total } = await loadMoreOptions(allOptions.length, searchInput);

    setTotalCount(total);
    setAllOptions((prevOptions) => [...prevOptions, ...newOptions]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!loadOptionsOnScroll) return;

    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && !isLoading) {
        loadOptionsOnScroll();
      }
    };

    const dropdown = document.querySelector(`#${labelKey} .rbt-menu`);
    if (dropdown) {
      dropdown.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (dropdown) {
        dropdown.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadOptionsOnScroll, isLoading]);

  return (
    <div id={labelKey} className={`${className} v2-input`}>
      <div className={`${error && submitted && 'has-error'} select-wrapper`}>
        <Tooltip
          hideTooltip={!disabled || !tooltipText}
          tooltipContent={tooltipText}
          portal={false}
        >
          <div className="select">
            {descriptionPosition == DescriptionPosition.TOP && (
              <span className="typeahead-select-label">{description}</span>
            )}
            <Typeahead
              {...props}
              ref={ref}
              options={allOptions}
              id="select-input"
              placeholder={placeholder}
              filterBy={disableFilter ? () => true : filterBy || [`${labelKey}`]}
              onInputChange={(searchText) => {
                setSearchInput(searchText);
                onInputChange && onInputChange(searchText);
              }}
              renderMenuItemChildren={(value) => {
                if (!props.renderMenuItemChildren) return;
                return props.renderMenuItemChildren(value[labelKey], value, isLoadingFlag);
              }}
            />

            {descriptionPosition == DescriptionPosition.BOTTOM && (
              <span className="typeahead-select-label">{description}</span>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
});
