import React, { useEffect, useState } from 'react';
import { EllipsisText, Toast, Loader } from '@getvim/atomic-ui';
import './styles.less';
import { Table } from 'react-bootstrap';
import { Button } from '@getvim/atomic-ui';
import { SelectTypeahead } from '../select-typeahead';
import ConfirmDeleteModal from '../confirm-delete-modal';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import { ItemModal } from '../problems-modal/itemModal';
import useApi from '../../hooks/useApi';
import moment from 'moment';
import { Patient, PatientProblem, PatientProblemStatus } from '../../types';
import Formatter from '../../utils/formatter';

const { ToastTypes, createToast } = Toast;

const ProblemsList = ({ patient, onDataUpdate }: { patient: Patient, onDataUpdate: (problemList: any) => void}) => {
  const api = useApi();

  const [isCreateProblemModalOpen, setCreateProblemModalOpenStatus] = useState(false);
  const [activeProblems, setActiveProblems] = useState<PatientProblem[]>([]);
  const [isLoading, setLoading] = useState(false);

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [problemToBeDeleted, setProblemToBeDeleted] = useState<any>(null);

  useEffect(() => {
    getActiveProblemList();
  }, []);

  const mapProblemList = (data) => {
    return data.map((problem) => {
      return { code: problem.taxonomy?.label, description: problem.taxonomy?.description, id: problem.id };
    });
  }

  const getActiveProblemList = async () => {
    const status = [
      PatientProblemStatus.Active,
      PatientProblemStatus.Stable,
      PatientProblemStatus.Remission,
    ];
    const data = await api.getPatientProblems(patient.id, { status });
    setLoading(false);
    setActiveProblems([...data]);
    onDataUpdate(mapProblemList([...data]));
  };

  const openModal = () => {
    setCreateProblemModalOpenStatus(true);
  };
  const closeModal = () => {
    setCreateProblemModalOpenStatus(false);
    getActiveProblemList();
  };

  const removeItem = async (item: any) => {
    setLoading(true);
    await api.deletePatientProblem({ patientId: patient.id, id: item.id });
    getActiveProblemList();
  };

  const onUpdateProblemStatus = async (item, status: PatientProblemStatus) => {
    try {
      setLoading(true);
      await api.updatePatientProblem(patient.id, item.id, { status });
      getActiveProblemList();
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.statusCode != '400' ? 'Something went wrong' : err.error?.message?.[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const problemStatusOptions = Object.values(PatientProblemStatus).map(function (status) {
    return { name: status };
  });

  return (
    <div className="problem-list-content v2">
      <div className="headerb">
        <div className="headerb__title"> Problems List </div>
        <Button buttonType="small" onClick={openModal}>
          + New Problem
        </Button>
      </div>

      {isLoading ? (
        <Loader type="dots" size="small" label="Loading" />
      ) : (
        <Table striped borderless hover>
          <thead>
            <tr>
              <th className="column__label">ICD</th>
              <th>Description</th>
              <th>Notes</th>
              <th className="column__status">Status</th>
              <th className="column__data">Added On</th>
              <th className="column__data">Modified On</th>
              <th>Modified By</th>
              <th className="column__remove-icon" />
            </tr>
          </thead>
          <tbody>
            {activeProblems.map((item: PatientProblem) => {
              const selectedStatus = [
                problemStatusOptions.find((option) => option.name === item.status),
              ];
              const providerName = `${item.notes[0]?.provider?.firstName} ${item.notes[0]?.provider?.lastName}`;
              return (
                <>
                  <tr key={item.id}>
                    <td>{item.taxonomy?.label}</td>
                    <td>
                      <div className="wrapped">
                        <EllipsisText
                          tooltipContent={Formatter.toSentenceCase(item.taxonomy?.description)}
                          text={Formatter.toSentenceCase(item.taxonomy?.description)}
                          width="large"
                          bgColor="darkBlue"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="wrapped">
                        <EllipsisText
                          tooltipContent={item.notes[0]?.notes}
                          text={item.notes[0]?.notes}
                          width="large"
                          bgColor="darkBlue"
                        />
                      </div>
                    </td>
                    <td>
                      <SelectTypeahead
                        labelKey="name"
                        disableFilter
                        // @ts-ignore
                        inputProps={{ readOnly: true }}
                        options={problemStatusOptions}
                        renderMenuItemChildren={(name: React.ReactNode) => {
                          return <div>{name}</div>;
                        }}
                        onChange={(option: { name: PatientProblemStatus }[]) => {
                          if (selectedStatus[0]?.name == option[0].name) return;
                          onUpdateProblemStatus(item, option[0].name);
                        }}
                        description=""
                        className="status-selector select-container clean-input-container v2-input"
                        error={undefined}
                        selected={selectedStatus}
                      />
                    </td>
                    <td>{moment(item.notes.at(-1)?.createdAt).format('DD/MM/YYYY')}</td>
                    <td>{moment(item.notes.at(0)?.createdAt).format('DD/MM/YYYY')}</td>
                    <td>{providerName}</td>
                    <td className="column__remove-icon">
                      <Button
                        buttonType="link"
                        onClick={(e: any) => {
                          e.preventDefault();
                          setProblemToBeDeleted(item);
                          setConfirmDeleteModalOpen(true);
                        }}
                        className="problem-delete-btn"
                      >
                        <IconTrash className="trash-icon" />
                      </Button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      )}
      {isCreateProblemModalOpen ? (
        <ItemModal patient={patient} onClose={closeModal} onSave={closeModal} />
      ) : null}
      {confirmDeleteModalOpen && problemToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted="this problem"
          onConfirmed={() => {
            removeItem(problemToBeDeleted);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
          closeOnBackdrop={false}
        />
      )}
    </div>
  );
};

export default ProblemsList;
